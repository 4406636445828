.burgerMenu {
  background: var(--menuBg) center center no-repeat;
  height: var(--navMenuIconheight);
  width: var(--navMenuIconwidth);
}
.logo {
  background: var(--navbarLogo) center center no-repeat;
  //height: 26px;
  // height: 50px;
  height:var(--logoHeight);
  width: var(--navbarLogoWidth);
}

.search {
  background: var(--searchBg) center center no-repeat;
  height: var(--navSearchIconheight);
  width: var(--navSearchIconwidth);
}

.logo-container {
  width: 50%;
  text-align: center;
}

@media(max-width: 700px){

  .yMobileLogo
  {
    width: 200px!important;
    background-size: 100%!important;
  
  }

  .nav_yMobile {
    // padding: 25px 25px 15px 25px!important;
}

}
