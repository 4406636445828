/*! * * * * * * * * * * * * * * * * * * * *\  
  CSShake :: shake-hard
  v1.6.0
  CSS classes to move your DOM
  (c) 2015 @elrumordelaluz
  http://elrumordelaluz.github.io/csshake/
  Licensed under MIT
\* * * * * * * * * * * * * * * * * * * * */
@import url(../../../node_modules/csshake/dist/csshake.min.css);
@import url("https://fonts.googleapis.com/css?family=Anton");
.shake-hard {
  display: inherit;
  transform-origin: center center; }

.shake-freeze,
.shake-constant.shake-constant--hover:hover,
.shake-trigger:hover .shake-constant.shake-constant--hover {
  animation-play-state: paused; }

.shake-freeze:hover,
.shake-trigger:hover .shake-freeze, .shake-hard:hover,
.shake-trigger:hover .shake-hard {
  animation-play-state: running; }

@keyframes shake-hard {
  2% {
    transform: translate(-5px, 4px) rotate(0.5deg); }
  4% {
    transform: translate(8px, 5px) rotate(2.5deg); }
  6% {
    transform: translate(4px, -5px) rotate(0.5deg); }
  8% {
    transform: translate(-4px, -4px) rotate(3.5deg); }
  10% {
    transform: translate(-3px, -6px) rotate(1.5deg); }
  12% {
    transform: translate(-5px, 5px) rotate(2.5deg); }
  14% {
    transform: translate(-4px, 10px) rotate(1.5deg); }
  16% {
    transform: translate(-7px, 2px) rotate(3.5deg); }
  18% {
    transform: translate(-2px, 2px) rotate(-1.5deg); }
  20% {
    transform: translate(9px, -5px) rotate(-0.5deg); }
  22% {
    transform: translate(10px, -1px) rotate(-1.5deg); }
  24% {
    transform: translate(-1px, 3px) rotate(2.5deg); }
  26% {
    transform: translate(6px, -9px) rotate(1.5deg); }
  28% {
    transform: translate(-7px, -2px) rotate(-2.5deg); }
  30% {
    transform: translate(4px, 0px) rotate(-2.5deg); }
  32% {
    transform: translate(-8px, -4px) rotate(-1.5deg); }
  34% {
    transform: translate(-4px, -2px) rotate(2.5deg); }
  36% {
    transform: translate(-1px, -6px) rotate(0.5deg); }
  38% {
    transform: translate(6px, 6px) rotate(-2.5deg); }
  40% {
    transform: translate(1px, 2px) rotate(2.5deg); }
  42% {
    transform: translate(5px, -2px) rotate(3.5deg); }
  44% {
    transform: translate(-9px, -5px) rotate(-1.5deg); }
  46% {
    transform: translate(-5px, -2px) rotate(-2.5deg); }
  48% {
    transform: translate(-4px, -4px) rotate(1.5deg); }
  50% {
    transform: translate(2px, -3px) rotate(-1.5deg); }
  52% {
    transform: translate(-9px, -2px) rotate(-0.5deg); }
  54% {
    transform: translate(2px, -9px) rotate(0.5deg); }
  56% {
    transform: translate(7px, 1px) rotate(3.5deg); }
  58% {
    transform: translate(7px, 6px) rotate(0.5deg); }
  60% {
    transform: translate(-2px, 2px) rotate(3.5deg); }
  62% {
    transform: translate(10px, 3px) rotate(3.5deg); }
  64% {
    transform: translate(6px, 0px) rotate(-2.5deg); }
  66% {
    transform: translate(-6px, 0px) rotate(-1.5deg); }
  68% {
    transform: translate(3px, -1px) rotate(-1.5deg); }
  70% {
    transform: translate(-4px, 0px) rotate(1.5deg); }
  72% {
    transform: translate(-2px, 3px) rotate(-1.5deg); }
  74% {
    transform: translate(3px, -9px) rotate(0.5deg); }
  76% {
    transform: translate(5px, -6px) rotate(-0.5deg); }
  78% {
    transform: translate(-5px, 9px) rotate(-0.5deg); }
  80% {
    transform: translate(1px, -1px) rotate(-1.5deg); }
  82% {
    transform: translate(4px, -2px) rotate(1.5deg); }
  84% {
    transform: translate(3px, 0px) rotate(-2.5deg); }
  86% {
    transform: translate(8px, 8px) rotate(1.5deg); }
  88% {
    transform: translate(10px, -2px) rotate(-1.5deg); }
  90% {
    transform: translate(3px, 5px) rotate(1.5deg); }
  92% {
    transform: translate(4px, -7px) rotate(-1.5deg); }
  94% {
    transform: translate(-1px, 4px) rotate(-0.5deg); }
  96% {
    transform: translate(-4px, 8px) rotate(-0.5deg); }
  98% {
    transform: translate(7px, -7px) rotate(2.5deg); }
  0%, 100% {
    transform: translate(0, 0) rotate(0); } }

.shake-hard:hover,
.shake-trigger:hover .shake-hard, .shake-hard.shake-freeze, .shake-hard.shake-constant {
  animation-name: shake-hard;
  animation-duration: 100ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; }

/*.wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;

        .item {
            padding: 50px;
            display: flex;
            align-items: center;
            flex-direction: column;

            .icon {
                font-size: 60px;
                cursor: pointer;
            }

            p {
                font-size: 25px;
                color: #fff;
                font-family: Anton;
                font-weight: 700;
                letter-spacing: 1px;
            }
        }
    }*/
/*.games>div>a>div
{
    color:var(--gamename) ;
}

.games>div
{
    background-color: var(--gameBgColor);
    border-radius: var(--gameBorderradius);
    margin-right: var(--gameMargin);
}
.games>div>a
{
    margin : var(--linkGamemargin) !important;
    
}
.games>div>a img
{
    border-radius: var(--imgBorder)!important;
}*/
.carousel .thumbs-wrapper {
  display: none; }

img:not([alt]) {
  outline: none !important; }

.carousel .carousel-status {
  display: none; }

body[dir="rtl"] .carousel .slider-wrapper {
  direction: initial; }

body[dir="rtl"] .carousel .control-prev.control-arrow {
  /*right: 0;
    left: auto;*/ }

body[dir="rtl"] .carousel .control-next.control-arrow {
  /*left: 0;
    right: auto;*/ }

.banner img {
  width: 100%;
  height: 100%;
  /*background-repeat: no-repeat;
    background-size: contain;

    height: 426px;
    background-size: cover;*/ }

.gamezroidbanner {
  width: 50%;
  margin: auto; }

.Enter {
  background-repeat: no-repeat;
  background-size: contain;
  padding: 10px;
  width: 18%;
  padding: 14px 3px;
  height: 75px;
  background-color: #25807600;
  position: fixed;
  top: 300px;
  left: 43%;
  font-weight: 800; }

@media (max-width: 1050px) {
  .Enter {
    top: 267px;
    left: 42%; } }

@media (max-width: 950px) {
  .Enter {
    top: 207px;
    left: 42%; } }

@media (max-width: 768px) {
  .Enter {
    top: 198px;
    left: 42%; }
  .gamezroidbanner {
    width: 100%; } }

@media (max-width: 540px) {
  .Enter {
    top: 147px;
    left: 42%; } }

@media (max-width: 418px) {
  .Enter {
    top: 106px;
    left: 42%; } }

@media (max-width: 375px) {
  .Enter {
    top: 98px;
    left: 42%; } }

@media (max-width: 360px) {
  .Enter {
    top: 92px;
    left: 42%; } }

@media (max-width: 320px) {
  .Enter {
    top: 81px;
    left: 42%; } }
