.containerComp {
  background: var(--homepageBgCompetition);
  background-size: cover; }

.compbuttons {
  display: flex;
  flex-direction: row;
  border: 1px solid red; }

.compbuttons > div {
  flex: 50%;
  border: 1px solid green; }

.leaderboard {
  background: var(--leaderboard-icon);
  background-repeat: no-repeat;
  background-size: cover;
  float: left; }

.tos {
  background: var(--help-icon);
  background-repeat: no-repeat;
  background-size: cover;
  float: right; }

.compbtn button {
  width: 70px;
  height: 70px;
  cursor: pointer;
  border: none;
  outline: none; }

.moreGame {
  width: 260px;
  height: 90px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  background-color: transparent; }

.compHomeGames {
  width: 56% !important;
  margin-top: 2px !important;
  flex-wrap: wrap; }

.btnspage {
  min-height: 85px;
  width: 56%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.btnhomeramdan {
  background: url("../../assets/images/HomepagecomGamezroid/homebtbg.png") no-repeat center center;
  background-size: contain;
  color: var(--textColor);
  padding: 10px;
  height: 69px;
  line-height: 9px;
  width: 110px;
  border: 1px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  font-family: SourceSansPro-Bold;
  transition: all ease 0.4s; }

.btnhomeramdan p {
  margin-top: 11px; }

.imgGame img {
  width: 100px;
  height: 100px; }

.gameCompName {
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  color: #fff;
  padding: 2px 7px 9px 7px;
  text-transform: uppercase; }

.coins {
  width: 100px;
  height: 75px;
  position: absolute;
  top: 22%;
  left: 24%; }

body[dir='rtl'] .btnhomeramdan {
  font-size: 20px; }

@media (max-width: 1024px) {
  .compHomeGames {
    width: 61% !important; }
  .coins {
    left: -26px; } }

@media (max-width: 950px) {
  .compHomeGames {
    width: 30% !important; }
  .containerComp {
    min-height: 113vh !important;
    background-size: cover; } }

@media (max-width: 768px) {
  .btnspage {
    width: 62%; }
  .compbtn {
    width: 65% !important; }
  .spaceTop {
    height: 3vh !important; }
  .compHomeGames {
    width: 65% !important; }
  .containerComp {
    min-height: 113vh !important;
    background-size: cover; }
  .imgGame img {
    width: 90px;
    height: 90px; }
  .section-home {
    width: 72% !important; } }

@media (max-width: 540px) {
  .btnspage {
    width: 75%; }
  .containerComp {
    height: 719px !important;
    background-size: cover; }
  .compHomeGames {
    width: 77% !important; }
  .spaceTop {
    height: 7vh !important; }
  .section-home {
    width: 86% !important; }
  .compbtn {
    width: 80% !important; } }

@media (max-width: 418px) {
  .btnspage {
    width: 100%; }
  .containerComp {
    min-height: 112vh;
    background-size: cover; }
  .compbtn {
    width: 100% !important; }
  .spaceTop {
    height: 6vh !important; } }

@media (max-width: 418px) {
  .containerComp {
    height: 873px !important;
    background-size: cover; }
  .compHomeGames {
    width: 100% !important; } }

@media (max-width: 411px) {
  .spaceTop {
    height: 2vh !important; }
  .containerComp {
    background-size: cover; } }

@media (max-width: 400px) {
  .containerComp {
    height: 838px !important;
    background-size: cover; } }

@media (max-width: 376px) {
  .containerComp {
    height: 814px !important;
    min-height: 108vh !important;
    background-size: cover; }
  .coins {
    top: 102px; } }

@media (max-width: 360px) {
  .spaceTop {
    height: 5vh !important; }
  .coins {
    top: 120px; } }

@media (max-width: 320px) {
  .links-SectionCompetition {
    width: 98% !important; }
  .containerComp {
    height: 567px !important;
    min-height: 130vh !important;
    background-size: cover; }
  .compHomeGames {
    width: 100% !important; }
  .imgGame img {
    width: 75px;
    height: 75px; }
  .coins {
    top: 100px;
    left: -3px; }
  .btnhomeramdan {
    width: 100px; }
  .headerCompetion {
    margin-top: 0 !important; } }

@media (max-width: 280px) {
  .containerComp {
    height: 651px !important;
    min-height: 114vh !important;
    background-size: cover; }
  .compHomeGames {
    width: 100% !important; }
  .links-SectionCompetition {
    width: 100% !important; }
  .logoComp {
    width: 130px !important; } }

@media (max-device-width: 1024px) and (orientation: landscape) {
  .containerComp {
    min-height: 109vh !important; } }

@media (max-device-width: 993px) and (orientation: landscape) {
  .containerComp {
    min-height: 170vh !important; } }

@media (max-device-width: 882px) and (orientation: landscape) {
  .containerComp {
    min-height: 162vh !important; } }

@media (max-device-width: 823px) and (orientation: landscape) {
  .containerComp {
    min-height: 172vh !important; } }

@media (max-device-width: 768px) and (orientation: landscape) {
  .containerComp {
    min-height: 184vh !important; } }

@media (max-device-width: 640px) and (orientation: landscape) {
  .spaceTop {
    height: 4vh !important; }
  .containerComp {
    min-height: 162vh !important; } }

@media (max-device-width: 653px) and (orientation: landscape) {
  .spaceTop {
    height: 0 !important; }
  /*.containerComp {
        min-height: 226vh !important;
    }*/ }

.ice {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 200;
  overflow: hidden;
  pointer-events: none; }

.g-snows {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0; }

.g-snows > li {
  opacity: 0;
  position: absolute;
  top: 0;
  border-radius: 100%;
  background: url("../../assets/images/competition/partical1.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  animation-name: snow-drop;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards; }
  .g-snows > li:nth-child(1) {
    left: 41%;
    width: 2px;
    height: 2px;
    animation-duration: 8813ms;
    animation-delay: 757ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(2) {
    left: 140%;
    width: 1px;
    height: 1px;
    animation-duration: 6802ms;
    animation-delay: 3368ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(3) {
    left: 94%;
    width: 10px;
    height: 10px;
    animation-duration: 7645ms;
    animation-delay: 4331ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(4) {
    left: 40%;
    width: 4px;
    height: 4px;
    animation-duration: 7308ms;
    animation-delay: 4978ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(5) {
    left: 121%;
    width: 14px;
    height: 14px;
    animation-duration: 7860ms;
    animation-delay: 685ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(6) {
    left: 10%;
    width: 19px;
    height: 19px;
    animation-duration: 13410ms;
    animation-delay: 3962ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(7) {
    left: 29%;
    width: 12px;
    height: 12px;
    animation-duration: 9810ms;
    animation-delay: 4328ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(8) {
    left: 1%;
    width: 16px;
    height: 16px;
    animation-duration: 14183ms;
    animation-delay: 171ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(9) {
    left: 62%;
    width: 8px;
    height: 8px;
    animation-duration: 5581ms;
    animation-delay: 2221ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(10) {
    left: 42%;
    width: 18px;
    height: 18px;
    animation-duration: 6466ms;
    animation-delay: 1838ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(11) {
    left: 25%;
    width: 6px;
    height: 6px;
    animation-duration: 7988ms;
    animation-delay: 4256ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(12) {
    left: 37%;
    width: 14px;
    height: 14px;
    animation-duration: 7729ms;
    animation-delay: 1392ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(13) {
    left: 112%;
    width: 9px;
    height: 9px;
    animation-duration: 5497ms;
    animation-delay: 4059ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(14) {
    left: 38%;
    width: 3px;
    height: 3px;
    animation-duration: 9150ms;
    animation-delay: 329ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(15) {
    left: 91%;
    width: 17px;
    height: 17px;
    animation-duration: 9276ms;
    animation-delay: 2565ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(16) {
    left: 20%;
    width: 21px;
    height: 21px;
    animation-duration: 12108ms;
    animation-delay: 2058ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(17) {
    left: 134%;
    width: 3px;
    height: 3px;
    animation-duration: 11479ms;
    animation-delay: 4081ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(18) {
    left: 16%;
    width: 4px;
    height: 4px;
    animation-duration: 12348ms;
    animation-delay: 4775ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(19) {
    left: 28%;
    width: 2px;
    height: 2px;
    animation-duration: 7611ms;
    animation-delay: 3611ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(20) {
    left: 74%;
    width: 5px;
    height: 5px;
    animation-duration: 5448ms;
    animation-delay: 1411ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(21) {
    left: 72%;
    width: 2px;
    height: 2px;
    animation-duration: 6414ms;
    animation-delay: 2957ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(22) {
    left: 34%;
    width: 3px;
    height: 3px;
    animation-duration: 5703ms;
    animation-delay: 3224ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(23) {
    left: 19%;
    width: 8px;
    height: 8px;
    animation-duration: 8087ms;
    animation-delay: 3685ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(24) {
    left: 42%;
    width: 28px;
    height: 28px;
    animation-duration: 7728ms;
    animation-delay: 1069ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(25) {
    left: 61%;
    width: 18px;
    height: 18px;
    animation-duration: 12268ms;
    animation-delay: 2071ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(26) {
    left: 11%;
    width: 6px;
    height: 6px;
    animation-duration: 5008ms;
    animation-delay: 3980ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(27) {
    left: 39%;
    width: 4px;
    height: 4px;
    animation-duration: 6584ms;
    animation-delay: 4114ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(28) {
    left: 36%;
    width: 10px;
    height: 10px;
    animation-duration: 5311ms;
    animation-delay: 554ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(29) {
    left: 13%;
    width: 12px;
    height: 12px;
    animation-duration: 6408ms;
    animation-delay: 4905ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(30) {
    left: 79%;
    width: 15px;
    height: 15px;
    animation-duration: 8662ms;
    animation-delay: 184ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(31) {
    left: 77%;
    width: 1px;
    height: 1px;
    animation-duration: 6383ms;
    animation-delay: 2708ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(32) {
    left: 4%;
    width: 3px;
    height: 3px;
    animation-duration: 11483ms;
    animation-delay: 514ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(33) {
    left: 67%;
    width: 5px;
    height: 5px;
    animation-duration: 9071ms;
    animation-delay: 3102ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(34) {
    left: 46%;
    width: 6px;
    height: 6px;
    animation-duration: 7223ms;
    animation-delay: 4657ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(35) {
    left: 20%;
    width: 2px;
    height: 2px;
    animation-duration: 10476ms;
    animation-delay: 1734ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(36) {
    left: 48%;
    width: 23px;
    height: 23px;
    animation-duration: 10068ms;
    animation-delay: 1369ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(37) {
    left: 110%;
    width: 4px;
    height: 4px;
    animation-duration: 11231ms;
    animation-delay: 2053ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(38) {
    left: 28%;
    width: 5px;
    height: 5px;
    animation-duration: 9861ms;
    animation-delay: 4693ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(39) {
    left: 43%;
    width: 4px;
    height: 4px;
    animation-duration: 8295ms;
    animation-delay: 2522ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(40) {
    left: 111%;
    width: 9px;
    height: 9px;
    animation-duration: 7371ms;
    animation-delay: 2089ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(41) {
    left: 110%;
    width: 11px;
    height: 11px;
    animation-duration: 8806ms;
    animation-delay: 3463ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(42) {
    left: 50%;
    width: 11px;
    height: 11px;
    animation-duration: 10294ms;
    animation-delay: 2611ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(43) {
    left: 113%;
    width: 8px;
    height: 8px;
    animation-duration: 10891ms;
    animation-delay: 1773ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(44) {
    left: 63%;
    width: 22px;
    height: 22px;
    animation-duration: 5090ms;
    animation-delay: 4504ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(45) {
    left: 81%;
    width: 2px;
    height: 2px;
    animation-duration: 8336ms;
    animation-delay: 3187ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(46) {
    left: 125%;
    width: 6px;
    height: 6px;
    animation-duration: 5285ms;
    animation-delay: 2469ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(47) {
    left: 114%;
    width: 0px;
    height: 0px;
    animation-duration: 11641ms;
    animation-delay: 1253ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(48) {
    left: 55%;
    width: 9px;
    height: 9px;
    animation-duration: 11810ms;
    animation-delay: 4173ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(49) {
    left: 18%;
    width: 9px;
    height: 9px;
    animation-duration: 7309ms;
    animation-delay: 3202ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(50) {
    left: 52%;
    width: 21px;
    height: 21px;
    animation-duration: 11506ms;
    animation-delay: 2208ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(51) {
    left: 4%;
    width: 5px;
    height: 5px;
    animation-duration: 10055ms;
    animation-delay: 877ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(52) {
    left: 72%;
    width: 19px;
    height: 19px;
    animation-duration: 5023ms;
    animation-delay: 2057ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(53) {
    left: 64%;
    width: 14px;
    height: 14px;
    animation-duration: 11333ms;
    animation-delay: 1319ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(54) {
    left: 83%;
    width: 5px;
    height: 5px;
    animation-duration: 9942ms;
    animation-delay: 3887ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(55) {
    left: 9%;
    width: 1px;
    height: 1px;
    animation-duration: 5468ms;
    animation-delay: 3934ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(56) {
    left: 53%;
    width: 21px;
    height: 21px;
    animation-duration: 10179ms;
    animation-delay: 3260ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(57) {
    left: 50%;
    width: 8px;
    height: 8px;
    animation-duration: 13116ms;
    animation-delay: 3829ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(58) {
    left: 7%;
    width: 13px;
    height: 13px;
    animation-duration: 5537ms;
    animation-delay: 4104ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(59) {
    left: 41%;
    width: 16px;
    height: 16px;
    animation-duration: 8952ms;
    animation-delay: 2811ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(60) {
    left: 21%;
    width: 5px;
    height: 5px;
    animation-duration: 9569ms;
    animation-delay: 359ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(61) {
    left: 8%;
    width: 3px;
    height: 3px;
    animation-duration: 10864ms;
    animation-delay: 1085ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(62) {
    left: 49%;
    width: 24px;
    height: 24px;
    animation-duration: 10515ms;
    animation-delay: 923ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(63) {
    left: 16%;
    width: 16px;
    height: 16px;
    animation-duration: 7256ms;
    animation-delay: 1329ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(64) {
    left: 5%;
    width: 8px;
    height: 8px;
    animation-duration: 5613ms;
    animation-delay: 2957ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(65) {
    left: 61%;
    width: 21px;
    height: 21px;
    animation-duration: 8281ms;
    animation-delay: 2932ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(66) {
    left: 17%;
    width: 12px;
    height: 12px;
    animation-duration: 7906ms;
    animation-delay: 2230ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(67) {
    left: 23%;
    width: 24px;
    height: 24px;
    animation-duration: 9435ms;
    animation-delay: 2299ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(68) {
    left: 30%;
    width: 11px;
    height: 11px;
    animation-duration: 7223ms;
    animation-delay: 3059ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(69) {
    left: 93%;
    width: 13px;
    height: 13px;
    animation-duration: 11267ms;
    animation-delay: 927ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(70) {
    left: 71%;
    width: 16px;
    height: 16px;
    animation-duration: 6508ms;
    animation-delay: 1916ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(71) {
    left: 117%;
    width: 27px;
    height: 27px;
    animation-duration: 8978ms;
    animation-delay: 4906ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(72) {
    left: 74%;
    width: 1px;
    height: 1px;
    animation-duration: 6204ms;
    animation-delay: 3238ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(73) {
    left: 107%;
    width: 5px;
    height: 5px;
    animation-duration: 12247ms;
    animation-delay: 4265ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(74) {
    left: 84%;
    width: 10px;
    height: 10px;
    animation-duration: 7903ms;
    animation-delay: 2433ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(75) {
    left: 1%;
    width: 9px;
    height: 9px;
    animation-duration: 6017ms;
    animation-delay: 4551ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(76) {
    left: 12%;
    width: 19px;
    height: 19px;
    animation-duration: 12898ms;
    animation-delay: 849ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(77) {
    left: 120%;
    width: 25px;
    height: 25px;
    animation-duration: 10456ms;
    animation-delay: 111ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(78) {
    left: 53%;
    width: 14px;
    height: 14px;
    animation-duration: 6647ms;
    animation-delay: 319ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(79) {
    left: 7%;
    width: 18px;
    height: 18px;
    animation-duration: 11285ms;
    animation-delay: 1183ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(80) {
    left: 64%;
    width: 20px;
    height: 20px;
    animation-duration: 12463ms;
    animation-delay: 2637ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(81) {
    left: 28%;
    width: 10px;
    height: 10px;
    animation-duration: 7314ms;
    animation-delay: 350ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(82) {
    left: 15%;
    width: 11px;
    height: 11px;
    animation-duration: 7303ms;
    animation-delay: 3289ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(83) {
    left: 41%;
    width: 5px;
    height: 5px;
    animation-duration: 6265ms;
    animation-delay: 2670ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(84) {
    left: 30%;
    width: 5px;
    height: 5px;
    animation-duration: 7142ms;
    animation-delay: 4484ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(85) {
    left: 114%;
    width: 7px;
    height: 7px;
    animation-duration: 7792ms;
    animation-delay: 4792ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(86) {
    left: 93%;
    width: 20px;
    height: 20px;
    animation-duration: 7945ms;
    animation-delay: 2135ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(87) {
    left: 67%;
    width: 12px;
    height: 12px;
    animation-duration: 5289ms;
    animation-delay: 3225ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(88) {
    left: 72%;
    width: 2px;
    height: 2px;
    animation-duration: 5595ms;
    animation-delay: 3461ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(89) {
    left: 40%;
    width: 2px;
    height: 2px;
    animation-duration: 5389ms;
    animation-delay: 1886ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(90) {
    left: 28%;
    width: 14px;
    height: 14px;
    animation-duration: 10569ms;
    animation-delay: 806ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(91) {
    left: 49%;
    width: 7px;
    height: 7px;
    animation-duration: 7024ms;
    animation-delay: 3297ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(92) {
    left: 34%;
    width: 7px;
    height: 7px;
    animation-duration: 5136ms;
    animation-delay: 2586ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(93) {
    left: 33%;
    width: 0px;
    height: 0px;
    animation-duration: 6633ms;
    animation-delay: 839ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(94) {
    left: 54%;
    width: 0px;
    height: 0px;
    animation-duration: 10409ms;
    animation-delay: 3164ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(95) {
    left: 10%;
    width: 7px;
    height: 7px;
    animation-duration: 5423ms;
    animation-delay: 2784ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(96) {
    left: 15%;
    width: 14px;
    height: 14px;
    animation-duration: 7506ms;
    animation-delay: 565ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(97) {
    left: 95%;
    width: 1px;
    height: 1px;
    animation-duration: 11599ms;
    animation-delay: 1433ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(98) {
    left: 13%;
    width: 2px;
    height: 2px;
    animation-duration: 10917ms;
    animation-delay: 3364ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(99) {
    left: 22%;
    width: 19px;
    height: 19px;
    animation-duration: 10675ms;
    animation-delay: 4294ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(100) {
    left: 20%;
    width: 12px;
    height: 12px;
    animation-duration: 10617ms;
    animation-delay: 3589ms;
    filter: blur(1px); }

@keyframes snow-drop {
  0% {
    transform: translate(0, 0);
    opacity: 0.5;
    margin-left: 0; }
  10% {
    margin-left: 15px; }
  20% {
    margin-left: 20px; }
  25% {
    transform: translate(0, 166.66667px);
    opacity: 0.75; }
  30% {
    margin-left: 15px; }
  40% {
    margin-left: 0; }
  50% {
    transform: translate(0, 333.33333px);
    opacity: 1;
    margin-left: -15px; }
  60% {
    margin-left: -20px; }
  70% {
    margin-left: -15px; }
  75% {
    transform: translate(0, 500px);
    opacity: 0.5; }
  80% {
    margin-left: 0; }
  100% {
    transform: translate(0, 666.66667px);
    opacity: 0; } }
