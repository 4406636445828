/**
 Default Brand
 **/

html,
html[data-theme="default"] {
  --primaryColor: #009edd;
  --activebgTaps : #009edd;
  --borderActiveTab : #009edd;
  --titleColor : #009edd;
  --activeColor:#009edd;
  --borderActiveColor: #009edd;
  --vodColor: #ff3030;
  --orgColor: #ff6e23;
  --etsColor: #008734;
  --mobColor: #009edd;
  --stcColor: #613bff;
  --sabafonColor: #ff6e23;
  --zainColor: #3ef886;
  --ooredoocolor :#ff6e23;
  --zainjordancolor:#37ace5;
  --gamezroidcolor: #f2d12c;
  --darkPrimaryColor: #004f6f;
  --secondaryBgColor: #12151d;
  --BgNavScroll : #12151d;
  --loginLogoHeight : 51px;
  --lightBgColor: #333742;
  --darkBgColor: #1b1f2a;
  --homeBgColor:#1b1f2a;
  --appBg:#1b1f2a;
  --textColor: #ffffff;
  --subBorderColor:#fff;
  --paymentTextcolor : #ffffff;
  --mainTabsColor: #ffffff;
  --gameDetails : #ffffff;
  --sidebardetailscolor: #ffffff;
  --gamename:#ffffff;
  --unSubscribeColor: #ffffff;
  --sSubtabColor: #fff;

  --borderRadiusSize : 22px;
  --homeTabs: #27313b;
  --LoveColor: #f4323d;
  --gameBgColor : #1b1f2a;
  --gameBorderradius : 0;
  --gameMargin : 0;
  --background-size: 160px;
  --loginLogoWidth : 160px;
  --navbarLogoWidth : 160px;
  --navSearchIconheight : 17px;
    --navSearchIconwidth : 17px;
    --navMenuIconheight : 20px;
    --navMenuIconwidth : 25px;
  --gameDetailsType : rgb(120, 120, 120);
  --Allcolor: #ffffff;
  --backgroundcat :#1a1f2a;
  --activeBackground: #1a1f2a;
  --bgHeaderMenucolor:#1a1f2a;
  --linkGamemargin : 20px 3px 5px 15px;
  --imgBorder : 22%;
  --tabMargin :0;
  --gameBorderB :1px solid rgb(66, 69, 74);
  --gameMarginTop : 0;
  --heightSubTabs: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
  --rankingHeader : 211px;
  --rankingHeadercontent : 200;
  --colorActive : #fff;
  --paddingTaps : 0;
  --marginScroll: 0 0;
  --opacityScroll : 'none';
  --profileTextColor : #fff;
  --loginLogo: url(../assets/images/logos/default/playit-with-text.svg);
  --navbarLogo: url(../assets/images/logos/default/playit.svg);
  --leaderboard : url(../assets/images/leaderboard/bg.svg);
  --paymentLogo : url(../assets/images/logos/default/fawry_logo.svg);
  --play : url(../assets/images/logos/gamezroid/play.png);
  --exclusiveGame : url(../assets/images/background/EX.png);
  --exclusiveGamePart2 : url(../assets/images/background/EX.png);
  --competitionBg : url(../assets/images/competetion-new.jpg);
  --menuBg:url(../assets/icons/burger-menu.svg);
  --searchBg:url(../assets/icons/search.svg);
  --EgyBanner: url(../assets/images/Egypt/banner.svg);
  --insideBg:'';
  --loginBg:'';
  --navHight: 61px;
  --logoHeight: 50px;
  --EnfontFamily : 'SourceSansPro-Regular';
  --EnHeadlinefontFamily: 'SourceSansPro-Regular';

  --ArfontFamily : 'droid-Kufi';
  --ArHeadlinefontFamily: 'droid-Kufi';


  

}

html.theme-transition,
html.theme-transition *,
html.theme-transition *:before,
html.theme-transition *:after {
  transition: all 750ms ;
  transition-delay: 0 ;
}

/**
 Branded Operators
 **/

@import "themes/_mtnYemen.scss";
@import "themes/_mtnSyria.scss";
@import "themes/_zainSudan.scss";
@import "themes/_expressoSenegal.scss";
@import "themes/_playit77.scss";
@import "themes/_orangeEgypt.scss";
@import "themes/_ooredoo.scss";
@import "themes/_ooredoops.scss";
@import "themes/_ooredooz.scss";
@import 'themes/_zainjordan.scss';
@import "themes/_gamezroid.scss";
@import "themes/_ethioGamezroid";

@import "themes/_stcKuwit.scss";
@import "themes/_sabafon.scss";
@import "themes/_africell";
@import "themes/_gamezone.scss";
@import "themes/_ethioGamezone.scss";

@import "themes/_gamezonePlayit.scss";

@import "themes/_vodafone.scss";
@import "themes/playFree.scss";
@import "themes/_nigeria.scss";
@import "themes/_jawwalgamezroid.scss";
@import "themes/_zainIq.scss";
@import "themes/gamezroidCompetition.scss";
@import "themes/asiacell.scss";
@import "./themes/mtn.scss";
@import "./themes/_duwifi.scss";
@import "./themes/_korekiq.scss";
@import "./themes/_mauritania.scss";
@import "./themes/_yemenMobile.scss";
